import "../css/background.css";
import React from 'react';


function Back() {


    return (
        <React.Fragment>
            <div className="body">

            </div>
        </React.Fragment>
    )
}
export default Back;