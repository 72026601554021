
import "../css/bottomarrow.css";
import React from "react";



function Arrow() {
    return(
        <React.Fragment>
           <div id="arrow">
      <a href="#homeLink">
        {' '} {'   '}

        <i class="fa" >  &#xf106;</i>



      </a>
         </div>
    </React.Fragment>
    )
    }
    
    export default Arrow;