import "../css/rainbow.css";

import React from 'react';


function Rainbow() {


    return (
        <React.Fragment>
            <div className="seperator gradient">
            </div>

        </React.Fragment>
    )

}
export default Rainbow;