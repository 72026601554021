import "../css/border.css";

import React from 'react';


function Border() {


    return (
        <React.Fragment>
            <div className="circleWrapper">
                <div className="circle"></div>

            </div>
        </React.Fragment>
    )

}
export default
    Border;